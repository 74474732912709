<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Roles
        </h1>
        <LinkTemplate :to="{path:'/roles/create'}" class="my-6 ml-6">
            Add New Role
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="true" canview="/roles/" :columns="columns" :items="items"></TableTemplate>
    <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'RoleList',
    mixins: [List],
    data: () => ({
        action: '/roles',
        searchParams: {
            'model': 'Role',
            'fields': ['name'],
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'name',
                label:'Name',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>

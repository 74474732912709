<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Role
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/role/create" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <RadioGroup>
                    <Radio name="role_type" label="Parent" real_val="parent" v-model="item.type"></Radio>
                    <Radio name="role_type" label="Child" real_val="child" v-model="item.type"></Radio>
                </RadioGroup>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateRole',
    mixins: [Create],
    data: () => ({
        back: '/roles',
        item: {
            name: '',
            type: 'parent',
        },
        action: '/role/',
    }),
    computed: {
        formData() {
            return {
                'role':this.item,
            }
        },
    },
    props: [],
    methods: {
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>

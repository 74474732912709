<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Role
        </h1>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/role" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <RadioGroup>
                    <Radio name="role_type" label="Parent" real_val="parent" v-model="item.type"></Radio>
                    <Radio name="role_type" label="Child" real_val="child" v-model="item.type"></Radio>
                </RadioGroup>
            </FormTemplate>
        </div>
        <div class="" >
            <h2 class="text-red text-md">
                Child Roles
            </h2>
            <ul>
                <li v-for="(role,i) in item.children" :key="i">
                    <router-link class="underline hover:text-red cursor-pointer" :to="{path:`/roles/${role.id}`}" >{{role.name}}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'

export default {
    name: 'Role',
    mixins: [View],
    data: () => ({
        parameter: 'role_id',
        action: '/role/',
    }),
    computed: {
        formData() {
            return {
                'role':this.item,
            }
        },
    },
    props: [],
    methods: {
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
